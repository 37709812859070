<template>
  <div class="intro-container" :class="{ 'mobile-bg': isMobile, 'desktop-bg': !isMobile }">
    <!-- <div class="intro-content">
       <h1>교육원 소개</h1>
      <p>여기는 교육원 소개 페이지입니다.</p>
      <p v-if="isMobile"> 
         현재 디스플레이는<br>
         모바일 화면입니다.
      </p>
      <p v-else> 현재 디스플레이는 데스크탑 화면입니다.</p>
      <p>현재 화면 폭: {{ windowWidth }}px</p> 

      
    </div> -->
    <div class="content-image">
        <img src="../../assets/images/Intro1.png" alt="교육원 소개 이미지"   />
        <!-- <img src="../../assets/images/Intro2.png" alt="교육원 소개 이미지"   />
        <img src="../../assets/images/Intro3.png" alt="교육원 소개 이미지"   />
        <img src="../../assets/images/Intro4.png" alt="교육원 소개 이미지"   />
        <img src="../../assets/images/Intro5.png" alt="교육원 소개 이미지"   /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserHome',
  data() {
    return {
      isMobile: false,
      windowWidth: 0,
    };
  },
  mounted() {
    this.checkViewport();
    window.addEventListener('resize', this.checkViewport);
  },
  methods: {
    checkViewport() {
      this.isMobile = window.innerWidth <= 768;
      this.windowWidth = window.innerWidth;
      console.log('isMobile:'+this.isMobile);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkViewport);
  }
}
</script>

<style scoped>
/* 기본 스타일 */
.intro-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  padding: 0px;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
}

/* 모바일 배경 */
.mobile-bg {
  /* background-image: url('../../assets/images/mindle.png'); */
  background-size: cover;
  height: auto;
}

/* 데스크탑 배경 */
.desktop-bg {
  /* background-image: url('../../assets/images/mindle.png'); */
  background-size: cover;
  height: auto;
}

/* 콘텐츠 스타일 */
.intro-content {
  text-align: center;
  color: white;
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  max-width: 90%;
  box-sizing: border-box;
}

/* 이미지 스타일 */
.content-image {
  display: flex;
  flex-direction: column; /* 이미지를 세로로 나열 */
  justify-content: flex-start;
  align-items: flex-start; /* 왼쪽 정렬 */
  width: 100%;
  padding: 0;
  margin: 0;
}

.content-image img {
  width: 100%; /* 이미지를 부모 요소 너비에 맞춤 */
  height: auto; /* 비율에 맞춰 높이를 자동 조정 */
  margin: 0; /* 이미지 간의 간격 없애기 */
  padding: 0;
  object-fit: contain; /* 이미지가 잘리지 않고 화면에 맞게 조정 */
}
/* 제목과 본문 스타일 */
h1 {
  font-size: 2rem;
}

p {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .intro-content {
    font-size: 1rem;
    padding: 15px;
    max-width: 100%;
  }

  h1 {
    font-size: 1.5rem;
  }
}
</style>
